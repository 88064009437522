export const MAX_ITEMS_PER_PAGE = 10;
export const GLOBAL_QUERY_PARAMS = { limit: MAX_ITEMS_PER_PAGE };

export interface GetItemsOptions {
  // Whether to return the raw response body instead of just ._embedded
  raw?: boolean;
  query?: Record<any, any>;
  queryAsSearchParams?: URLSearchParams;
  uuid?: string;
  forceRefresh?: boolean;
}

export interface GetItemOptions {
  uuid?: string;
  forceRefresh?: boolean;
}

export interface RunQueryOptions {
  // Whether to retrieve the next page
  nextPage?: boolean;
}
