import { LoadJobModel } from '@/module/api/load-job';
import { EntityType } from '@/module/api/domo/five9-entities.interface';
import { ExportStateName } from '@/module/api/export';
import { JobProgressDto } from '@/module/api/common';

export enum DomainAccessLevelName {
  DEFAULT = 'RW',
  READ = 'R',
  READWRITE = 'RW',
  NONE = 'NONE'
}

export enum Five9RegionName {
  DEFAULT = 'US',
  US = 'US',
  CA = 'CA',
  EU = 'EU',
  UK = 'UK'
}

export const Five9RegionList = [
  Five9RegionName.DEFAULT,
  Five9RegionName.US,
  Five9RegionName.CA,
  Five9RegionName.EU,
  Five9RegionName.UK
];

export enum Five9SectorName {
  DEFAULT = 'prod',
  PROD = 'prod',
  ALPHA = 'alpha'
}
export interface Five9ProvisioningValues {
  skillGroups: number;
  inboundCampaigns: number;
  outboundCampaigns: number;
  callVariables: number;
  crmVariables: number;
  inboundVoiceLines: number;
  outboundVoiceLines: number;
  namedAgents: number;
}

export const emptyFive9ProvisioningValues = (): Five9ProvisioningValues => {
  return {
    skillGroups: 0,
    inboundCampaigns: 0,
    outboundCampaigns: 0,
    callVariables: 0,
    crmVariables: 0,
    inboundVoiceLines: 0,
    outboundVoiceLines: 0,
    namedAgents: 0
  };
};

export const domainAccessLevelList = () => {
  return [DomainAccessLevelName.NONE, DomainAccessLevelName.READ, DomainAccessLevelName.READWRITE];
};

export const domainAccessLevelListSelector = () => {
  return [
    { text: 'None', value: DomainAccessLevelName.NONE },
    { text: 'Read Only', value: DomainAccessLevelName.READ },
    { text: 'Read and Write', value: DomainAccessLevelName.READWRITE }
  ];
};

export const five9RegionList = () => {
  return [Five9RegionName.US, Five9RegionName.CA, Five9RegionName.EU, Five9RegionName.UK];
};

export const five9SectorList = () => {
  return [Five9SectorName.PROD, Five9SectorName.ALPHA];
};

export interface DomainVersions {
  isMonolith?: boolean;
}

export interface DomainModel {
  uuid: string;
  userId: string;
  organizationId: string;
  label: string;
  access: {
    permissions: DomainAccessLevelName;
    credentials: string;
  };
  domainId: string;
  domainName: string;
  sector: Five9SectorName;
  region: Five9RegionName;
  provisioning?: Record<string, any>;
  mostRecentExtractJob?: {
    domainUuid: string;
    domoSetUuid: string;
    isRestorePoint?: boolean;
    state: ExportStateName;
    uuid: string;
    label: string;
    progress: JobProgressDto;
    createdAt: string;
    updatedAt: string;
    objectSummary?: {
      total: number;
      totalByType: Partial<Record<EntityType, number>>;
    };
  };
  mostRecentLoadJob?: LoadJobModel;
}

// Asymmetric API, so we have a separate request model
// TODO: Need to be able to send label field.
export interface DomainRequestModel {
  permissions: DomainAccessLevelName;
  five9AgentUsername: string;
  five9AgentPassword: string;
  label: string;
  sector: Five9SectorName;
  region: Five9RegionName;
  provisioning: Record<string, any>;
  // Only on update
  uuid?: string;
}

export interface DomainCredentialModel {
  five9AgentUsername: string;
  five9AgentPassword: string;
  permissions: DomainAccessLevelName;
}

// Data for DomainFormMeta.vue
export interface DomainMetaInput {
  sector: Five9SectorName;
  region: Five9RegionName;
  domainName: string;
  domainId: string;
  label: string;
  uuid?: string;
}

// Data for DomainFormAccess.vue
export type DomainAccessInput = DomainCredentialModel;
// Dto for createOrUpdate from combined form dialog
export interface CreateOrUpdateParams {
  domainMetaInput: DomainMetaInput;
  domainAccessInput: DomainAccessInput;
  domainProvisioningInput: Five9ProvisioningValues;
}
