import '@/lib/log';
import axios from 'axios';
import Vue from 'vue';

import { UserMessageError } from '@/lib/user-message-error';
import { isDateStringExpired } from '@/module/api/domo';
import { ExportStateName } from '@/module/api/export';
import { BaseItemState, BaseUseItemModel } from '@/module/shared/base.use-crud.interface';
import { computed, ref } from '@vue/composition-api';
import _ from 'lodash';
import { GetItemsOptions } from '../common';
import { LoadJobStateName, LoadJobType, SourceType, UnitLabelName } from '../load-job';
import {
  DomainAccessLevelName,
  DomainCredentialModel,
  DomainModel,
  DomainRequestModel,
  DomainVersions,
  Five9RegionName,
  Five9SectorName
} from './domain.interface';

const apiPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/domain`;
const defaultMaxResultLimit = -1;

export const emptyItem = (): DomainModel => {
  return {
    uuid: '',
    userId: '',
    organizationId: '',
    label: '',
    access: {
      permissions: DomainAccessLevelName.DEFAULT,
      credentials: ''
    },
    domainId: '',
    domainName: '',
    region: Five9RegionName.DEFAULT,
    sector: Five9SectorName.DEFAULT,
    provisioning: {
      skillsLimit: 0,
      cavsLimit: 0,
      campaigns: 0,
      inboundVoiceLinesLimit: 0,
      textInteractionsLimit: 50,
      isE164: false,
      isTcpa: false,
      socialEnabled: false,
      adtCustomization: false,
      visualIvrEnabled: false
    },
    mostRecentExtractJob: {
      uuid: '',
      domainUuid: '',
      domoSetUuid: '',
      state: ExportStateName.NEW,
      label: '',
      isRestorePoint: false,
      objectSummary: {
        total: 0,
        totalByType: {}
      },
      progress: {
        totalUnitsCompleted: 0,
        totalUnits: 0,
        unitLabel: UnitLabelName.CRAWL_JOBS
      },
      createdAt: '',
      updatedAt: ''
    },
    mostRecentLoadJob: {
      uuid: '',
      domainUuid: '',
      domoSetUuid: '',
      label: '',
      type: LoadJobType.IMPORT,
      state: LoadJobStateName.NEW,
      source: {
        type: SourceType.DOMAIN,
        uuid: ''
      },
      progress: {
        totalUnitsCompleted: 0,
        totalUnits: 0,
        unitLabel: UnitLabelName.CRAWL_JOBS
      },
      createdAt: '',
      updatedAt: ''
    }
  };
};

export const state: BaseItemState<DomainModel> = {
  items: ref<DomainModel[]>([]),
  selectedItemId: ref(''),
  selectedItem: ref(emptyItem()),
  createdItemId: ref(''),
  createdItem: ref(emptyItem()),
  isLoading: ref(false)
};

export function useDomainApi(
  useGlobalQueryParams: Record<any, any> | undefined = undefined
): BaseUseItemModel<DomainModel> & any {
  Vue.$log.debug('Loaded with query', useGlobalQueryParams);

  // TODO: remove this; it duplicates emptyItem
  const emptyDomainModel = () => {
    return emptyItem();
  };

  const findItem = (uuid: string): DomainModel | undefined => {
    if (!uuid) {
      return undefined;
    }
    Vue.$log.debug(`Finding item ${uuid} from items: `, state.items.value);
    const item = state.items.value.find((i: DomainModel) => i.uuid === uuid);
    if (!item) {
      return undefined;
    }
    return item;
  };

  const selectItem = async (uuid: string): Promise<DomainModel | undefined> => {
    if (!uuid) {
      Vue.$log.debug(`Setting selected item to empty because ${uuid} was given`);
      state.selectedItemId.value = '';
      state.selectedItem.value = emptyDomainModel();
      return;
    }
    Vue.$log.debug(`Selecting item ${uuid} from items: `, state.items.value);
    const item = findItem(uuid);
    if (!item) {
      Vue.$log.debug(`Item ${uuid} not found`);
      return undefined;
    }
    Vue.$log.debug(`Selected item ${uuid}`, item);
    state.selectedItemId.value = item?.uuid ? item.uuid : '';
    state.selectedItem.value = item;
    return state.selectedItem.value;
  };

  const selectItemBy = async (key: string, value: string): Promise<DomainModel | undefined> => {
    if (!key || !value) {
      Vue.$log.debug('selectItemBy: Key or value is missing');
      return undefined;
    }
    Vue.$log.debug(`Selecting item ${value} from items: `, state.items.value);
    const item = state.items.value.find((i: any) => i?.[key] === value);
    if (!item) {
      Vue.$log.debug(`Selected item by ${key} not found`);
      return undefined;
    }
    state.selectedItemId.value = item?.uuid ? item.uuid : '';
    Vue.$log.debug(`Selected item by ${key}`, item);
    state.selectedItem.value = item;
    return state.selectedItem.value;
  };

  const getItems = async (options?: GetItemsOptions): Promise<DomainModel[] | undefined> => {
    state.isLoading.value = true;
    const combinedQueryParams = Object.assign(
      {},
      {
        limit: defaultMaxResultLimit
      },
      useGlobalQueryParams,
      options?.query
    );
    Vue.$log.debug('Domain: Fetching items with query params: ', combinedQueryParams);
    return axios
      .get(apiPath, { params: combinedQueryParams })
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        // Update the list of items.
        state.items.value = res.data?._embedded ? res.data._embedded : res.data;
        // Update the selected item.
        if (state.selectedItem?.value?.uuid !== undefined) {
          for (const item of state.items.value) {
            if (item?.uuid === state.selectedItem.value.uuid) {
              state.selectedItem.value = item;
            }
          }
        }
        return options?.raw ? res.data : state.items.value;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err.message);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const addOrReplaceItemInItems = (newItem: DomainModel): void => {
    if (state.items.value.some((item: DomainModel) => item.uuid === newItem.uuid)) {
      state.items.value.forEach((item: DomainModel, i: number) => {
        if (item.uuid === newItem.uuid) {
          Vue.$log.debug(`Replacing item in items: ${newItem.uuid}`);
          // Note: we must splice instead of assign to trigger render update.
          state.items.value.splice(i, 1, newItem);
        }
      });
    } else {
      Vue.$log.debug(`Adding domain to items: ${newItem.uuid}`);
      state.items.value.push(newItem);
    }
    if (newItem?.uuid !== undefined && state.selectedItem?.value?.uuid === newItem?.uuid) {
      Vue.$log.debug(`Replacing selected item: ${newItem.uuid}`);
      state.selectedItem.value = newItem;
    }
  };

  const getItem = async (uuid: string): Promise<DomainModel | undefined> => {
    if (!uuid) {
      const errMessage = 'Cannot get domain without a uuid';
      Vue.$log.error(errMessage);
      return undefined;
    }
    state.isLoading.value = true;
    return axios
      .get<DomainModel>(`${apiPath}/${uuid}`)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        if (!res?.data?.uuid) {
          Vue.$log.debug('FAIL! uuid is not in response so cannot add item');
          return res.data;
        }
        addOrReplaceItemInItems(res.data);
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err.message);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const getVersions = async (uuid: string): Promise<DomainVersions | undefined> => {
    if (!uuid) {
      const errMessage = 'Cannot get domain without a uuid';
      Vue.$log.error(errMessage);
      return undefined;
    }
    state.isLoading.value = true;
    return axios
      .get<DomainVersions>(`${apiPath}/${uuid}/versions`)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err.message);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const getDomainAccessCredentials = async (
    uuid: string
  ): Promise<DomainCredentialModel | undefined> => {
    if (!uuid) {
      throw new Error('Can not get item without ID');
    }
    state.isLoading.value = true;
    return axios
      .get<DomainCredentialModel>(`${apiPath}/${uuid}/access`)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err.message);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const updateDomainAccessCredentials = async (
    uuid: string,
    item: Partial<DomainCredentialModel>
  ): Promise<DomainCredentialModel | UserMessageError | undefined> => {
    if (!item || !uuid) {
      throw new Error('Can not update item without ID');
    }
    state.isLoading.value = true;
    return axios
      .put<DomainCredentialModel>(`${apiPath}/${uuid}/access`, item)
      .then((res) => {
        Vue.$log.debug('Response from API for credential update ', res.data);
        return Promise.all([res, getItem(uuid)]);
      })
      .then(([credResponse, getItemResponse]) => {
        return credResponse.data;
      })
      .catch((err) => {
        const errMessage = 'Error updating credentials.';
        Vue.$log.error(errMessage, err.message);
        if (err.response?.data?.message !== undefined) {
          return new UserMessageError(err.response.data.message);
        }
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const fieldsToOmitOnTransaction = ['mostRecentExtractJob', 'mostRecentLoadJob', 'uuid'];

  const createItem = async (
    item: DomainRequestModel
  ): Promise<DomainModel | UserMessageError | undefined> => {
    Vue.$log.debug('Creating new item: ', item);
    state.isLoading.value = true;
    const body = _.omit(item, fieldsToOmitOnTransaction);
    return axios
      .post<DomainModel>(`${apiPath}`, body, {
        headers: { 'content-type': 'application/json' }
      })
      .then((res: any) => {
        if (res.data) {
          Vue.$log.debug('Successfully created item. Refreshing items.');
          state.createdItem.value = res.data;
          state.createdItemId.value = res.data.uuid!; // eslint-disable-line
          addOrReplaceItemInItems(res.data);
          return res.data;
        } else {
          const errMessage = 'Invalid response creating item';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error caught creating item.';
        Vue.$log.error(errMessage, err.message);
        if (err.response?.data?.message !== undefined) {
          return new UserMessageError(err.response.data.message);
        }
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const fieldsToOmitOnUpdate = [
    'mostRecentExtractJob',
    'mostRecentLoadJob',
    'uuid',
    'region',
    'domainName'
  ];

  const updateItem = async (
    item: Partial<DomainRequestModel>
  ): Promise<DomainModel | Error | undefined> => {
    Vue.$log.debug('Updating item: ', item);
    const body = _.omit(item, fieldsToOmitOnUpdate);
    state.isLoading.value = true;
    return axios
      .patch<DomainModel>(apiPath + '/' + item.uuid, body)
      .then((res) => {
        if (res.data) {
          Vue.$log.debug('Successfully updated item. Refreshing item.');
          addOrReplaceItemInItems(res.data);
          return res.data;
        } else {
          const errMessage = 'Invalid response updating item';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err: any) => {
        const errMessage = 'Error updating item';
        Vue.$log.error(errMessage, err.message);
        if (err.response?.data?.message !== undefined) {
          return new UserMessageError(err.response.data.message);
        }
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const deleteItem = async (id: string): Promise<number | undefined> => {
    if (!id) {
      Vue.$log.error('Can not delete item without ID');
      return;
    }
    Vue.$log.debug('Deleting item: ', id);
    state.isLoading.value = true;
    return axios
      .delete<DomainModel>(`${apiPath}/${id}`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          Vue.$log.debug('Successfully deleted item. Refreshing items.');
          getItems();
        } else {
          Vue.$log.error('Invalid response deleting item: ', res);
        }
        return res.status;
      })
      .catch((err) => {
        Vue.$log.error('Error caught deleting item.', id, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const verifyItem = async (item: Partial<DomainModel>) => {
    // TODO: Add verification
    return true;
  };

  /**
   * Refresh the selected item. If it is not selected, select it.
   */
  const refreshItem = async (uuid: string, forceRefresh = false): Promise<void> => {
    Vue.$log.debug(`Refreshing DOMAIN item ${uuid} with force refresh ${forceRefresh}`);
    // First, attempt to select the item if it is not selected.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
    // Next, if we are forcing refresh or the item is not found (which we infer
    // from an empty selected item), go get it.
    if (uuid && (forceRefresh || state.selectedItem?.value?.uuid !== uuid)) {
      Vue.$log.debug(`Refreshing DOMAIN item ${uuid} resulting in re-retrieving item from API`);
      await getItem(uuid);
    }
    // Finally, if the selected item doesn't match, select it.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
  };

  /**
   * If true, that means that the domain's most recent extract job is newer than the most
   * recent load job. This means that our snapshot is fresh and we can do things with the
   * domain without being concerned we have stale data.
   */
  const isExportNewerThanLatestJobForDomain = (domain: DomainModel): boolean => {
    const mostRecentExtractJobState = domain?.mostRecentExtractJob?.state;
    if (mostRecentExtractJobState && mostRecentExtractJobState !== 'DONE') {
      Vue.$log.debug(
        `isExportNewerThanLatestJobForDomain: ${domain.uuid} has a last snapshot that is not DONE`
      );
      return false;
    }
    const mostRecentExtractJobDateString = domain?.mostRecentExtractJob?.updatedAt;
    if (mostRecentExtractJobDateString === undefined) {
      Vue.$log.debug(
        `isExportNewerThanLatestJobForDomain: ${domain.uuid} no recent extract job, so false`
      );
      return false;
    }
    if (isDateStringExpired(mostRecentExtractJobDateString)) {
      Vue.$log.debug(
        `isExportNewerThanLatestJobForDomain: ${domain.uuid} recent extract job expired, so false`
      );
      return false;
    }
    const mostRecentLoadJobDateString = domain?.mostRecentLoadJob?.updatedAt;
    if (mostRecentLoadJobDateString === undefined) {
      Vue.$log.debug(
        `isExportNewerThanLatestJobForDomain: ${domain.uuid} no recent load job, so true`
      );
      return true;
    }
    const mostRecentExtractJobDate = new Date(mostRecentExtractJobDateString);
    const mostRecentLoadJobDate = new Date(mostRecentLoadJobDateString);
    const isExportNewerThanLatestJobForDomain = mostRecentExtractJobDate > mostRecentLoadJobDate;
    Vue.$log.debug(
      `isExportNewerThanLatestJobForDomain: ${domain.uuid} ${mostRecentExtractJobDateString} > ${mostRecentLoadJobDateString} = ${isExportNewerThanLatestJobForDomain}`
    );
    return isExportNewerThanLatestJobForDomain;
  };

  const isExportNewerThanLatestJob = computed((): boolean => {
    return isExportNewerThanLatestJobForDomain(state.selectedItem?.value);
  });

  const hasReadPermission = (domain: DomainModel): boolean => {
    if (
      domain?.access?.permissions === DomainAccessLevelName.READ ||
      domain?.access?.permissions === DomainAccessLevelName.READWRITE
    ) {
      return true;
    }
    return false;
  };

  const hasWritePermission = (domain: DomainModel): boolean => {
    if (domain?.access?.permissions === DomainAccessLevelName.READWRITE) {
      return true;
    }
    return false;
  };

  return {
    items: computed(() => state.items.value),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => state.selectedItem.value),
    selectedItemId: computed(() => state.selectedItemId.value),
    createdItemId: computed(() => state.createdItemId.value),
    createdItem: computed(() => state.createdItem.value),
    isLoading: computed(() => state.isLoading.value),
    isExportNewerThanLatestJobForDomain,
    isExportNewerThanLatestJob,
    hasReadPermission,
    hasWritePermission,
    getDomainAccessCredentials,
    updateDomainAccessCredentials,
    selectItemBy,
    getItem,
    getItems,
    getVersions,
    createItem,
    updateItem,
    selectItem,
    deleteItem,
    verifyItem,
    refreshItem,
    emptyDomainModel
  };
}
