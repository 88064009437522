import axios from 'axios';
import Vue from 'vue';
import '@/lib/log';
import { computed, ref } from '@vue/composition-api';
import { BaseItemState, BaseUseItemModel } from '@/module/shared/base.use-crud.interface';
import _ from 'lodash';
import {
  LoadJobModel,
  ValidationStateName,
  LoadJobStateName,
  LoadJobType,
  SourceType,
  UnitLabelName,
  LoadJobWizardSetupModel
} from './load-job.interface';
import { GetItemOptions, GetItemsOptions, ReportType } from '@/module/api/common';
import {
  DomoModifyOperationParams,
  DomoModifyOperationResponse,
  DomoModifyOperationListResponse
} from '@/module/api/domo';
import { format } from 'date-fns';
import { createToastInterface } from 'vue-toastification';
import { useFileUtils } from '@/utils';

const { createFileDownloadElement } = useFileUtils();

const toast = createToastInterface({ maxToasts: 3 });

const apiPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/load-job`;
const defaultMaxResultLimit = 10;

export const autoGeneratedLabelName = (item?: Partial<LoadJobModel>): string => {
  if (item?.type === undefined) {
    return '';
  }
  const type = item.type ? item.type : '';
  return `${type} ${format(new Date(), 'yyyy-MM-dd')}`;
};

export const emptyItem = (): LoadJobModel => {
  return {
    uuid: '',
    domoSetUuid: '',
    domainUuid: '',
    label: '',
    type: LoadJobType.IMPORT,
    state: LoadJobStateName.NEW,
    notificationEmail: '',
    source: {
      type: SourceType.DOMAIN,
      uuid: ''
    },
    mostRecentValidationJob: {
      uuid: '',
      state: ValidationStateName.RUNNING,
      validationSummary: {
        error: 0,
        notice: 0,
        warning: 0
      },
      createdAt: '',
      updatedAt: ''
    },
    operationSummary: {
      total: 0,
      totalsByState: {
        DONE: 0,
        ERRORED: 0
      },
      totalsByOperation: {
        CREATE: 0,
        UPDATE: 0,
        NULL: 0
      }
    },
    progress: {
      totalUnitsCompleted: 0,
      totalUnits: 0,
      unitLabel: UnitLabelName.LOADING_GROUPS
    },
    createdAt: '',
    updatedAt: '',
    wizards: {
      setup: {
        isComplete: false,
        step: 1,
        data: {}
      }
    }
  };
};

const state: BaseItemState<LoadJobModel> = {
  items: ref<LoadJobModel[]>([]),
  selectedItemId: ref(''),
  selectedItem: ref(emptyItem()),
  createdItemId: ref(''),
  createdItem: ref(emptyItem()),
  isLoading: ref(false)
};

export function useLoadJobApi(
  useGlobalQueryParams: Record<any, any> | undefined = undefined
): BaseUseItemModel<LoadJobModel> & any {
  Vue.$log.debug('Loaded with query', useGlobalQueryParams);

  const emptyLoadJobModel = () => {
    return emptyItem();
  };

  const selectItem = async (uuid: string): Promise<LoadJobModel | undefined> => {
    if (!uuid) {
      Vue.$log.debug(`Setting selected item to empty because ${uuid} was given`);
      state.selectedItemId.value = '';
      state.selectedItem.value = emptyLoadJobModel();
      return;
    }
    Vue.$log.debug(`Selecting item ${uuid} from items: `, state.items.value);
    const item = state.items.value.find((i: LoadJobModel) => i.uuid === uuid);
    if (!item) {
      Vue.$log.debug(`Item ${uuid} not found`);
      return undefined;
    }
    Vue.$log.debug(`Selected item ${uuid}`, item);
    state.selectedItemId.value = item?.uuid ? item.uuid : '';
    state.selectedItem.value = item;
    return state.selectedItem.value;
  };

  const selectItemBy = async (key: string, value: string): Promise<LoadJobModel | undefined> => {
    if (key && value) {
      Vue.$log.debug(`Selecting item ${value} from items: `, state.items.value);
      const item = state.items.value.find((i: any) => i?.[key] === value);
      if (item) {
        state.selectedItemId.value = item?.uuid ? item.uuid : '';
        Vue.$log.debug({ selected: item });
        state.selectedItem.value = item;
        return state.selectedItem.value;
      }
    }
  };

  const validItem = async (item: LoadJobModel) => {
    Vue.$log.debug('received item', item);
    if (!item.label || !item.domainUuid || !item.type) {
      const errMessage = 'Item not valid. Missing required properties: label, domainUuid, or type.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  const validUpdateItem = async (item: Partial<LoadJobModel>) => {
    if (!item.uuid && Object.keys(item).length < 2) {
      const errMessage = 'Item not valid. Need an id to update and at least one field.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  const getItems = async (options?: GetItemsOptions) => {
    try {
      state.isLoading.value = true;
      const combinedQueryParams = Object.assign(
        {},
        useGlobalQueryParams,
        {
          limit: defaultMaxResultLimit
        },
        options?.query
      );
      Vue.$log.debug('Load Job: Fetching items with query params: ', combinedQueryParams);
      const res = await axios.get(apiPath, { params: combinedQueryParams });
      Vue.$log.debug('Returned from API: ', res.data);
      // Update the list of items.
      state.items.value = res.data?._embedded ? res.data._embedded : res.data;
      // Update the selected item.
      if (state.selectedItem?.value?.uuid !== undefined) {
        for (const item of state.items.value) {
          if (item?.uuid === state.selectedItem.value.uuid) {
            state.selectedItem.value = item;
          }
        }
      }
      return options?.raw ? res.data : state.items.value;
    } catch (err) {
      const errMessage = 'Error loading.';
      Vue.$log.error(errMessage, err);
    } finally {
      state.isLoading.value = false;
    }
  };

  const addOrReplaceItemInItems = (newItem: LoadJobModel): void => {
    if (state.items.value.some((item: LoadJobModel) => item.uuid === newItem.uuid)) {
      state.items.value.forEach((item: LoadJobModel, i: number) => {
        if (item.uuid === newItem.uuid) {
          Vue.$log.debug(`Replacing item in items: ${newItem.uuid}`);
          // Note: we must splice instead of assign to trigger render update.
          state.items.value.splice(i, 1, newItem);
        }
      });
    } else {
      Vue.$log.debug(`Adding load job to items: ${newItem.uuid}`);
      state.items.value.push(newItem);
    }
    if (newItem?.uuid !== undefined && state.selectedItem?.value?.uuid === newItem?.uuid) {
      Vue.$log.debug(`Replacing selected item: ${newItem.uuid}`);
      state.selectedItem.value = newItem;
    }
  };

  const getItem = async (uuid: string) => {
    if (!uuid) {
      throw new Error('Can not get item without ID');
    }
    try {
      state.isLoading.value = true;
      const res = await axios.get<LoadJobModel>(`${apiPath}/${uuid}`);
      Vue.$log.debug('Returned from API: ', res.data);
      if (!res?.data?.uuid) {
        Vue.$log.debug('FAIL! uuid is not in response so cannot add item');
        return res.data;
      }
      addOrReplaceItemInItems(res.data);
      return res.data;
    } catch (err) {
      const errMessage = 'Error loading.';
      Vue.$log.error(errMessage, err);
    } finally {
      state.isLoading.value = false;
    }
  };

  const fieldsToOmitOnTransaction = [
    'uuid',
    'state',
    'updatedAt',
    'createdAt',
    'operationSummary',
    'progress',
    'mostRecentValidationJob'
  ];

  const createItem = async (item: LoadJobModel): Promise<LoadJobModel | undefined> => {
    if (item.label === undefined) {
      item.label = autoGeneratedLabelName(item);
    }
    if (!(await validItem(item))) {
      const errMessage = 'Not a valid load job model for creation';
      Vue.$log.error(errMessage, item);
      return undefined;
    }
    Vue.$log.debug('Creating new item: ', item);
    state.isLoading.value = true;
    const body = _.omit(item, fieldsToOmitOnTransaction);
    return axios
      .post<LoadJobModel>(`${apiPath}`, body, {
        headers: { 'content-type': 'application/json' }
      })
      .then((res) => {
        if (res.data) {
          Vue.$log.debug('Successfully created item. Refreshing items.');
          state.createdItem.value = res.data;
          state.createdItemId.value = res.data.uuid!; // eslint-disable-line
          return res.data;
        } else {
          const errMessage = 'Invalid response creating item';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error caught creating item.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const updateItem = async (item: Partial<LoadJobModel>): Promise<LoadJobModel | undefined> => {
    Vue.$log.debug('Updating item: ', item);
    state.isLoading.value = true;
    const body = _.omit(item, fieldsToOmitOnTransaction);
    Vue.$log.debug({ body });
    return axios
      .patch<LoadJobModel>(apiPath + '/' + item.uuid, body, {
        headers: { 'content-type': 'application/json' }
      })
      .then((res) => {
        if ((res?.data && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('Successfully set up load job with wizard config..', res);
          addOrReplaceItemInItems(res.data);
          return res.data;
        } else {
          const errMessage = 'Invalid response setting up load job with wizard config.';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error caught setting up load job with wizard config.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const getModifyOperations = async (uuid: string): Promise<DomoModifyOperationListResponse> => {
    state.isLoading.value = true;
    return axios
      .get<DomoModifyOperationListResponse>(`${apiPath}/${uuid}/bulk/modify`)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        if (!res?.data) {
          return {
            countSelected: 0,
            countTotal: 0,
            selectedItems: [],
            operations: []
          };
        }
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return {
          countSelected: 0,
          countTotal: 0,
          selectedItems: [],
          operations: []
        };
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const modifyOperations = async (
    uuid: string,
    operations: DomoModifyOperationParams
  ): Promise<DomoModifyOperationResponse | undefined> => {
    if (!operations) {
      throw new Error('Can not modify domos without any operation params');
    }
    state.isLoading.value = true;
    return axios
      .post<DomoModifyOperationResponse>(`${apiPath}/${uuid}/bulk/modify`, operations)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        if (!res?.data || !Array.isArray(res.data?.operations)) {
          return undefined;
        }
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const setupLoadJob = async (
    loadJobUuid: string,
    item: LoadJobWizardSetupModel
  ): Promise<number | undefined> => {
    Vue.$log.debug('Creating new item: ', item);
    if (!loadJobUuid || !item) {
      const errMessage =
        'Need both a load job uuid and update body to update the load job wizard status';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    const body = _.omit(item, fieldsToOmitOnTransaction);
    return axios
      .put<LoadJobWizardSetupModel>(`${apiPath}/${loadJobUuid}/wizard/setup`, body, {
        headers: { 'content-type': 'application/json' }
      })
      .then((res) => {
        if (res.status >= 200 || res.status <= 299) {
          Vue.$log.debug('Successfully set up load job with wizard config..', res);
        } else {
          const errMessage = 'Invalid response setting up load job with wizard config.';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught setting up load job with wizard config.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const applyWizardSetup = async (loadJobUuid: string): Promise<number | undefined> => {
    Vue.$log.debug('Applying wizard setup to load job: ', loadJobUuid);
    if (!loadJobUuid) {
      const errMessage =
        'Need both a load job uuid and update body to apply  the wizard setup to the load job.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .post<LoadJobWizardSetupModel>(`${apiPath}/${loadJobUuid}/wizard/setup/apply`)
      .then((res) => {
        if ((res?.status && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('Successfully applied wizard setup to laod job', res);
        } else {
          const errMessage = 'Invalid response applying wizard setup to load job.';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught applying wizard setup to load job.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const runLoadJob = async (loadJobUuid: string): Promise<number | undefined> => {
    Vue.$log.debug('Applying wizard setup to load job: ', loadJobUuid);
    if (!loadJobUuid) {
      const errMessage = 'Need both a load job uuid and update body to run the load job.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .post(`${apiPath}/${loadJobUuid}/transition/prepare`)
      .then((res) => {
        if ((res?.status && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('Successfully applied wizard setup to laod job', res);
        } else {
          const errMessage = 'Invalid response when trying to run the load job.';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught when trying to run the load job.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const cancelLoadJob = async (loadJobUuid: string): Promise<number | undefined> => {
    Vue.$log.debug('Cancelling load job: ', loadJobUuid);
    if (!loadJobUuid) {
      const errMessage = 'Need a load job uuid to cancel';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .post(`${apiPath}/${loadJobUuid}/transition/cancel`)
      .then((res) => {
        if ((res?.status && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('Successfully cancelled laod job', res);
        } else {
          const errMessage = 'Invalid response when trying to cancel the load job.';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught when trying to cancel the load job.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const downloadJobReport = async (
    loadJobUuid: string,
    reportType: ReportType = ReportType.DETAIL
  ) => {
    Vue.$log.debug('Downloading job report for load job: ', loadJobUuid);
    if (!loadJobUuid) {
      const errMessage = 'Need a uuid to download the load job report.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .get(`${apiPath}/${loadJobUuid}/report/${reportType}/download`)
      .then((res) => {
        if ((res?.status && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('successfully download report', res);
          return res.data;
        } else {
          const errMessage = 'Invalid response when trying to download the load job report.';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error caught when trying to run the load job.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const downloadPasswordReport = async (loadJobUuid: string) => {
    Vue.$log.debug('Downloading generated password report for load job: ', loadJobUuid);
    if (!loadJobUuid) {
      const errMessage = 'Need a uuid to download the load job report.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .get(`${apiPath}/${loadJobUuid}/report/user-credentials/download`)
      .then((res) => {
        if ((res?.status && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('successfully download report', res);
          return res.data;
        } else {
          const errMessage = 'Invalid response when trying to download the load job report.';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error caught when trying to run the load job.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const getManifest = async (loadJobUuid: string, reportType: ReportType = ReportType.DETAIL) => {
    Vue.$log.debug('Getting manifest report for load job: ', loadJobUuid);
    if (!loadJobUuid) {
      const errMessage = 'Need a uuid to get the manifest.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .get(`${apiPath}/${loadJobUuid}/manifest/report/${reportType}`)
      .then((res) => {
        if ((res?.status && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('successfully download report', res);
          return res.data;
        } else {
          const errMessage = 'Invalid response when trying to get the load job manifest.';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Invalid response when trying to get the load job manifest.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const downloadManifestReport = async (
    loadJobUuid: string,
    reportType: ReportType = ReportType.DETAIL
  ) => {
    Vue.$log.debug('Downloading manifest report for load job: ', loadJobUuid);
    if (!loadJobUuid) {
      const errMessage = 'Need a uuid to download the manifest report.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .get(`${apiPath}/${loadJobUuid}/manifest/report/${reportType}/download`)
      .then((res) => {
        if ((res?.status && res.status >= 200) || res.status <= 299) {
          Vue.$log.debug('successfully download report', res);
          return res.data;
        } else {
          const errMessage = 'Invalid response when trying to download the load job report.';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Invalid response when trying to download the load job report.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const deleteItem = async (id: string) => {
    if (!id) {
      Vue.$log.error('Can not delete item without ID');
      return;
    }
    Vue.$log.debug('Deleting item: ', id);
    try {
      state.isLoading.value = true;
      const res = await axios.delete<LoadJobModel>(`${apiPath}/${id}`);
      if (res.data) {
        Vue.$log.debug('Successfully deleted item. Refreshing items.');
        getItems();
      } else {
        Vue.$log.error('Invalid response deleting item: ', res);
      }
    } catch (err) {
      Vue.$log.error('Error caught deleting item.', id, err);
    } finally {
      state.isLoading.value = false;
    }
  };

  const verifyItem = async (item: Partial<LoadJobModel>) => {
    // TODO: Add verification
    return true;
  };

  // TODO: Make separate view for reports menu
  const downloadManifestDetailReport = async (loadJobUuid: string) => {
    state.isLoading.value = true;
    const report = await downloadManifestReport(loadJobUuid);
    if (!report) {
      const errMessage = 'Unable to download manifest report. There may not have been any results.';
      toast.error(errMessage);
      return;
    }
    const downloadElement = createFileDownloadElement({
      document,
      blobData: report,
      fileName: 'import-manifest-report.csv'
    });

    if (downloadElement?.click) {
      downloadElement.click();
      toast.success('Downloaded report');
      state.isLoading.value = false;
    } else {
      toast.error('Unable to download report');
      state.isLoading.value = false;
    }
  };

  const downloadJobDetailReport = async (loadJobUuid: string) => {
    state.isLoading.value = true;
    const report = await downloadJobReport(loadJobUuid);
    if (!report) {
      const errMessage = 'Unable to download detail report. There may not have been any results.';
      toast.error(errMessage);
      return;
    }
    const downloadElement = createFileDownloadElement({
      document,
      blobData: report,
      fileName: 'import-detail-report.csv'
    });

    if (downloadElement?.click) {
      downloadElement.click();
      toast.success('Downloaded report');
      state.isLoading.value = false;
    } else {
      toast.error('Unable to download report');
      state.isLoading.value = false;
    }
  };

  const downloadGeneratedPasswordReport = async (loadJobUuid: string) => {
    state.isLoading.value = true;
    const report = await downloadPasswordReport(loadJobUuid);
    if (!report) {
      const errMessage =
        'Unable to download agent password report. There may not have been any results.';
      toast.error(errMessage);
      return;
    }
    const downloadElement = createFileDownloadElement({
      document,
      blobData: report,
      fileName: 'agent-passwords-report.csv'
    });

    if (downloadElement?.click) {
      downloadElement.click();
      toast.success('Downloaded report');
      state.isLoading.value = false;
    } else {
      toast.error('Unable to download report');
      state.isLoading.value = false;
    }
  };

  /**
   * Refresh the selected item. If it is not selected, select it.
   */
  const refreshItem = async (uuid: string, forceRefresh = false): Promise<void> => {
    Vue.$log.debug(`Refreshing LOAD JOB item ${uuid} with force refresh ${forceRefresh}`);
    // First, attempt to select the item if it is not selected.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
    // Next, if we are forcing refresh or the item is not found (which we infer
    // from an empty selected item), go get it.
    if (uuid && (forceRefresh || state.selectedItem?.value?.uuid !== uuid)) {
      await getItem(uuid);
    }
    // Finally, if the selected item doesn't match, select it.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
  };

  return {
    items: computed(() => state.items.value),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => state.selectedItem.value),
    selectedItemId: computed(() => state.selectedItemId.value),
    createdItemId: computed(() => state.createdItemId.value),
    createdItem: computed(() => state.createdItem.value),
    isLoading: computed(() => state.isLoading.value),
    getModifyOperations,
    modifyOperations,
    downloadJobReport,
    setupLoadJob,
    applyWizardSetup,
    runLoadJob,
    cancelLoadJob,
    selectItemBy,
    refreshItem,
    getItem,
    getItems,
    getManifest,
    createItem,
    downloadManifestReport,
    downloadPasswordReport,
    downloadManifestDetailReport,
    downloadJobDetailReport,
    downloadGeneratedPasswordReport,
    updateItem,
    selectItem,
    deleteItem,
    verifyItem,
    emptyLoadJobModel
  };
}
