import '@/lib/log';
import axios from 'axios';
import Vue from 'vue';

import { GetItemsOptions } from '@/module/api/common';
import { DomoModel, DomoStateName } from '@/module/api/domo/domo.interface';
import {
  EntityType,
  Five9ConfigApiOperationTypeName
} from '@/module/api/domo/five9-entities.interface';
import { BaseItemState, BaseUseItemModel } from '@/module/shared/base.use-crud.interface';
import { computed, ref } from '@vue/composition-api';
import * as qs from 'qs';

const apiPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/domo`;
const defaultMaxResultLimit = 10;

export const emptyItem = (): DomoModel => {
  return {
    uuid: '',
    domoSetUuid: '',
    dombName: '',
    entityType: EntityType.USER,
    entityId: '',
    parentEntityId: '',
    operationType: Five9ConfigApiOperationTypeName.CREATE,
    state: DomoStateName.DONE,
    domb: {
      id: '',
      userName: '',
      password: '',
      kind: ''
    },
    result: '',
    createdAt: '',
    updatedAt: ''
  };
};

const state: BaseItemState<DomoModel> = {
  items: ref<DomoModel[]>([]),
  selectedItemId: ref(''),
  selectedItem: ref(emptyItem()),
  createdItemId: ref(''),
  createdItem: ref(emptyItem()),
  isLoading: ref(false)
};

export function useDomoApi(
  useGlobalQueryParams: Record<any, any> | undefined = undefined
): BaseUseItemModel<DomoModel> & any {
  Vue.$log.debug('Loaded with query', useGlobalQueryParams);

  const emptyDomoModel = (): DomoModel => {
    return emptyItem();
  };

  const selectItem = async (uuid: string): Promise<DomoModel | undefined> => {
    if (uuid) {
      Vue.$log.debug(`Selecting item ${uuid} from items: `, state.items);
      const item = state.items.value.find((i: DomoModel) => i.uuid === uuid);
      if (item) {
        state.selectedItemId.value = item?.uuid ? item.uuid : '';
        state.selectedItem.value = item;
        return state.selectedItem.value;
      }
    }
    return undefined;
  };

  const selectItemBy = async (key: string, value: string): Promise<DomoModel | undefined> => {
    if (key && value) {
      Vue.$log.debug(`Selecting item ${value} from items: `, state.items);
      const item = state.items.value.find((i: any) => i?.[key] === value);
      if (item) {
        state.selectedItemId.value = item?.uuid ? item.uuid : '';
        Vue.$log.debug({ selected: item });
        state.selectedItem.value = item;
        return state.selectedItem.value;
      }
    }
  };

  const getItems = async (options?: GetItemsOptions): Promise<DomoModel[] | undefined> => {
    state.isLoading.value = true;
    let combinedQueryParams: any = {};
    let queryPath = '';
    if (options?.queryAsSearchParams) {
      try {
        const params = !(options.queryAsSearchParams instanceof URLSearchParams)
          ? new URLSearchParams(options.queryAsSearchParams)
          : options.queryAsSearchParams;
        const queryString = params.toString();
        if (queryString !== '') {
          const deepObject = qs.parse(queryString);
          if (!deepObject || typeof deepObject !== 'object' || Object.keys(deepObject).length < 1) {
            throw new Error('Could not obtain deep query');
          }
          queryPath = '?' + qs.stringify(deepObject);
        }
      } catch (e) {
        Vue.$log.error('Could not make the API call', e.message);
        throw new Error('Could not make the API call');
      }
    } else {
      combinedQueryParams = Object.assign(
        {},
        useGlobalQueryParams,
        {
          limit: defaultMaxResultLimit
        },
        options?.query
      );
    }
    Vue.$log.debug('Domo: Fetching items with query params: ', combinedQueryParams);
    return axios
      .get(`${apiPath}${queryPath}`, { params: combinedQueryParams })
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        // Update the list of items.
        state.items.value = res.data?._embedded ? res.data._embedded : res.data;
        // Update the selected item.
        if (state.selectedItem?.value?.uuid !== undefined) {
          for (const item of state.items.value) {
            if (item?.uuid === state.selectedItem.value.uuid) {
              state.selectedItem.value = item;
            }
          }
        }
        return options?.raw ? res.data : state.items.value;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const addOrReplaceItemInItems = (newItem: DomoModel): void => {
    if (state.items.value.some((item: DomoModel) => item.uuid === newItem.uuid)) {
      state.items.value.forEach((item: DomoModel, i: number) => {
        if (item.uuid === newItem.uuid) {
          Vue.$log.debug(`Replacing item in items: ${newItem.uuid}`);
          // Note: we must splice instead of assign to trigger render update.
          state.items.value.splice(i, 1, newItem);
        }
      });
    } else {
      Vue.$log.debug(`Adding domo to items: ${newItem.uuid}`);
      state.items.value.push(newItem);
    }
    if (newItem?.uuid !== undefined && state.selectedItem?.value?.uuid === newItem?.uuid) {
      Vue.$log.debug(`Replacing selected item: ${newItem.uuid}`);
      state.selectedItem.value = newItem;
    }
  };

  const getItem = async (uuid: string): Promise<DomoModel | undefined> => {
    if (!uuid) {
      throw new Error('Can not get item without ID');
    }
    state.isLoading.value = true;
    return axios
      .get<DomoModel>(`${apiPath}/${uuid}`)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        if (!res?.data?.uuid) {
          Vue.$log.debug('FAIL! uuid is not in response so cannot add item');
          return res.data;
        }
        addOrReplaceItemInItems(res.data);
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const verifyItem = async (item: Partial<DomoModel>) => {
    // TODO: Add verification
    return true;
  };

  /**
   * Refresh the selected item. If it is not selected, select it.
   */
  const refreshItem = async (uuid: string, forceRefresh = false): Promise<void> => {
    Vue.$log.debug(`Refreshing DOMO item ${uuid} with force refresh ${forceRefresh}`);
    // First, attempt to select the item if it is not selected.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
    // Next, if we are forcing refresh or the item is not found (which we infer
    // from an empty selected item), go get it.
    if (forceRefresh || state.selectedItem?.value?.uuid !== uuid) {
      await getItem(uuid);
    }
    // Finally, if the selected item doesn't match, select it.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
  };

  return {
    items: computed(() => state.items),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => state.selectedItem),
    selectedItemId: computed(() => state.selectedItemId),
    createdItemId: computed(() => state.createdItemId),
    createdItem: computed(() => state.createdItem),
    isLoading: computed(() => state.isLoading.value),
    refreshItem,
    selectItemBy,
    getItem,
    getItems,
    selectItem,
    verifyItem,
    emptyDomoModel
  };
}
