export enum Five9ConfigApiOperationTypeName {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  NULL = 'NULL'
}

export enum EntityType {
  API_ERROR = 'API_ERROR',
  ENTITY_LISTING = 'ENTITY_LISTING',
  AGENT_GROUP = 'AGENT_GROUP',
  ALLOWED_SITES_SETTINGS = 'ALLOWED_SITES_SETTINGS',
  ANI_GROUP = 'ANI_GROUP',
  ANI_SETTINGS = 'ANI_SETTINGS',
  AUTH_PROFILE = 'AUTH_PROFILE',
  CALL_LIST = 'CALL_LIST',
  CALL_VARIABLE = 'CALL_VARIABLE',
  CAMPAIGN_PROFILE = 'CAMPAIGN_PROFILE',
  CAMPAIGN_PROFILE_FIELD_VIEW = 'CAMPAIGN_PROFILE_FIELD_VIEW', // Five9 is just FIELD_VIEW
  CAMPAIGN_AGENT_SCRIPT = 'CAMPAIGN_AGENT_SCRIPT',
  CAMPAIGN_SETTINGS = 'CAMPAIGN_SETTINGS',
  CAV_GROUP = 'CAV_GROUP',
  CERTIFICATE = 'CERTIFICATE',
  CERTIFICATE_SETTINGS = 'CERTIFICATE_SETTINGS',
  CLIENT_ACCESS_SETTINGS = 'CLIENT_ACCESS_SETTINGS',
  COLD_TRANSFER_SETTINGS = 'COLD_TRANSFER_SETTINGS',
  CONNECTOR = 'CONNECTOR',
  CONTACT_FIELD = 'CONTACT_FIELD',
  DISPOSITION = 'DISPOSITION',
  DISPOSITION_AGENT_NOTIFICATION = 'DISPOSITION_AGENT_NOTIFICATION',
  DISPOSITION_EMAIL_NOTIFICATION = 'DISPOSITION_EMAIL_NOTIFICATION',
  DISPOSITION_REPORTING_CALL_VARIABLE = 'DISPOSITION_REPORTING_CALL_VARIABLE',
  EMAIL_ATTACHMENT_UPLOADED_FILE = 'EMAIL_ATTACHMENT_UPLOADED_FILE', // Five9 is just EMAIL_UPLOADED_FILE
  EMAIL_SETTINGS = 'EMAIL_SETTINGS',
  EMAIL_TEMPLATE_UPLOAD_FILE = 'EMAIL_TEMPLATE_UPLOAD_FILE', // Five9 is just EMAIL_UPLOADED_FILE
  EXTENSION_RANGE = 'EXTENSION_RANGE',
  FIVE9_ACCESS = 'FIVE9_ACCESS',
  INBOUND_CAMPAIGN = 'INBOUND_CAMPAIGN',
  KEY_PERFORMANCE_INDICATORS_SETTINGS = 'KEY_PERFORMANCE_INDICATORS_SETTINGS',
  LOCAL_ANI = 'LOCAL_ANI',
  OTHER_SETTINGS = 'OTHER_SETTINGS',
  OUTBOUND_CAMPAIGN = 'OUTBOUND_CAMPAIGN',
  OUTBOUND_SETTINGS = 'OUTBOUND_SETTINGS',
  PASSWORD_POLICY = 'PASSWORD_POLICY',
  PROMPT = 'PROMPT',
  PROMPT_LANGUAGE = 'PROMPT_LANGUAGE',
  REASON_CODE = 'REASON_CODE',
  SCRIPTS = 'SCRIPTS',
  SCRIPTS_XML_DEFINITION = 'SCRIPTS_XML_DEFINITION',
  SESSION_EXPIRATION_SETTINGS = 'SESSION_EXPIRATION_SETTINGS',
  SKILLS = 'SKILLS',
  SOUND_SETTINGS = 'SOUND_SETTINGS',
  SPEED_DIAL = 'SPEED_DIAL',
  SSO_PROVIDER = 'SSO_PROVIDER',
  SSO_PROVIDER_CERT = 'SSO_PROVIDER_CERT',
  SYSTEM_SETTINGS = 'SYSTEM_SETTINGS',
  TASK_DIST_TYPE_SETTINGS = 'TASK_DIST_TYPE_SETTINGS',
  TIME_ZONE_DIALING_SETTINGS = 'TIME_ZONE_DIALING_SETTINGS',
  TRUST_IPS_SETTINGS = 'TRUST_IPS_SETTINGS',
  USER = 'USER',
  USER_CANNED_REPORT = 'USER_CANNED_REPORT',
  USER_GREETING = 'USER_GREETING',
  USER_PROFILE = 'USER_PROFILE',
  USER_PROFILE_FIELD_VIEW = 'USER_PROFILE_FIELD_VIEW', // Five9 is just FIELD_VIEW
  VELOCIFY_SETTINGS = 'VELOCIFY_SETTINGS',
  WEB_CUSTOMIZATION = 'WEB_CUSTOMIZATION',
  WORKFLOW_RULE = 'WORKFLOW_RULE'
}
