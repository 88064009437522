import '@/lib/log';
import { UserMessageError } from '@/lib/user-message-error';
import {
  CrawlJobInterface,
  CrawlJobRequestReport,
  ExportModel,
  ExportStateName
} from '@/module/api/export/export.interface';
import { UnitLabelName } from '@/module/api/load-job';
import { BaseItemState } from '@/module/shared/base.use-crud.interface';
import { toNum, useFileUtils } from '@/utils';
import { ComputedRef, computed, ref } from '@vue/composition-api';
import axios from 'axios';
import { format } from 'date-fns';
import _ from 'lodash';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';
import { GetItemsOptions, MAX_ITEMS_PER_PAGE, ReportType } from '../common';

const toast = createToastInterface({ maxToasts: 3 });

const { createFileDownloadElement } = useFileUtils();

const apiPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/extract-job`;

const defaultMaxResultLimit = MAX_ITEMS_PER_PAGE;

export const autoGeneratedLabelName = (): string => {
  return `Snapshot ${format(new Date(), 'yyyy-MM-dd h:mm a O')}`;
};

export const emptyItem = (): ExportModel => {
  return {
    uuid: '',
    domainUuid: '',
    domoSetUuid: '',
    state: ExportStateName.NEW,
    label: '',
    isRestorePoint: false,
    objectSummary: {
      total: 0,
      totalByType: {
        ANI_GROUP: 0,
        SCRIPTS: 0,
        SCRIPTS_XML_DEFINITION: 0
      }
    },
    progress: {
      totalUnitsCompleted: 0,
      totalUnits: 0,
      unitLabel: UnitLabelName.LOADING_GROUPS
    },
    createdAt: '',
    updatedAt: ''
  };
};

const state: BaseItemState<ExportModel> = {
  items: ref<ExportModel[]>([]),
  selectedItemId: ref(''),
  selectedItem: ref(emptyItem()),
  createdItemId: ref(''),
  createdItem: ref(emptyItem()),
  isLoading: ref(false)
};

export const getTotalObjects = (exportJob: ExportModel): number => {
  return toNum(exportJob?.objectSummary?.total);
};

class UseExportApiReturn {
  items: ComputedRef<ExportModel[]>;
  emptyItem: ComputedRef<() => ExportModel>;
  selectedItem: ComputedRef<ExportModel>;
  selectedItemId: ComputedRef<string>;
  createdItemId: ComputedRef<string>;
  createdItem: ComputedRef<ExportModel>;
  isLoading: ComputedRef<boolean>;
  downloadJobReport: (exportJobUuid: string, reportType?: ReportType) => Promise<any | undefined>;
  runDownloadJobReport: (exportJobUuid?: string) => Promise<void>;
  getJobErrorReport: (exportJobUuid: string, crawlJobUuid: string) => Promise<CrawlJobRequestReport[] | undefined>;
  runDownloadJobErrorReport: (
    exportJobUuid?: string,
    crawlJobUuid?: string,
    entityType?: string
  ) => Promise<void>;
  selectItemBy: (key: string, value: string) => Promise<ExportModel | undefined>;
  refreshItem: (uuid: string, forceRefresh?: boolean) => Promise<void>;
  getItem: (uuid: string) => Promise<ExportModel | undefined>;
  getItems: (options?: GetItemsOptions) => Promise<ExportModel[] | undefined>;
  getCrawlJobs: (uuid: string) => Promise<CrawlJobInterface[] | undefined>;
  createItem: (item: ExportModel) => Promise<ExportModel | undefined>;
  updateItem: (item: Partial<ExportModel>) => Promise<ExportModel | undefined>;
  selectItem: (uuid: string) => Promise<ExportModel | undefined>;
  deleteItem: (id: string) => Promise<number | undefined>;
  verifyItem: (item: Partial<ExportModel>) => void;
  emptyExportModel: () => ExportModel;
  queue: (uuid: string) => Promise<number | undefined>;
  cancelExportJob: (uuid: string) => Promise<number | undefined>;
}

export function useExportApi(
  useGlobalQueryParams: Record<any, any> | undefined = undefined
): UseExportApiReturn {
  Vue.$log.debug('Loaded with query', useGlobalQueryParams);

  const emptyExportModel = (): ExportModel => {
    return emptyItem();
  };

  const selectItem = async (uuid: string): Promise<ExportModel | undefined> => {
    if (!uuid) {
      Vue.$log.debug(`Setting selected item to empty because ${uuid} was given`);
      state.selectedItemId.value = '';
      state.selectedItem.value = emptyExportModel();
      return;
    }
    Vue.$log.debug(`Selecting item ${uuid} from items: `, state.items.value);
    const item = state.items.value.find((i: ExportModel) => i.uuid === uuid);
    if (!item) {
      Vue.$log.debug(`Item ${uuid} not found`);
      return undefined;
    }
    Vue.$log.debug(`Selected item ${uuid}`, item);
    state.selectedItemId.value = item?.uuid ? item.uuid : '';
    state.selectedItem.value = item;
    return state.selectedItem.value;
  };

  const selectItemBy = async (key: string, value: string): Promise<ExportModel | undefined> => {
    if (key && value) {
      Vue.$log.debug(`Selecting item ${value} from items: `, state.items.value);
      const item = state.items.value.find((i: any) => i?.[key] === value);
      if (item) {
        state.selectedItemId.value = item?.uuid ? item.uuid : '';
        Vue.$log.debug({ selected: item });
        state.selectedItem.value = item;
        return state.selectedItem.value;
      }
    }
  };

  const validItem = async (item: ExportModel): Promise<boolean> => {
    Vue.$log.debug('received item', item);
    if (!item.label || !item.domainUuid) {
      const errMessage = 'Item not valid. Missing required properties.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  const validUpdateItem = async (item: Partial<ExportModel>) => {
    if (!item.uuid && Object.keys(item).length < 2) {
      const errMessage = 'Item not valid. Need an id to update and at least one field.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  // TODO: deprecate 'raw' param because it changes the type
  const getItems = async (options?: GetItemsOptions): Promise<ExportModel[] | undefined> => {
    state.isLoading.value = true;
    Vue.$log.debug('Here were the options for the getExports request', options);
    const combinedQueryParams = Object.assign(
      {},
      {
        limit: defaultMaxResultLimit
      },
      useGlobalQueryParams,
      options?.query
    );
    Vue.$log.debug('Extract Job (export): Fetching items with query params: ', combinedQueryParams);
    return axios
      .get(apiPath, { params: combinedQueryParams })
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        // Update the list of items.
        state.items.value = res.data?._embedded ? res.data._embedded : res.data;
        // Update the selected item.
        if (state.selectedItem?.value?.uuid !== undefined) {
          for (const item of state.items.value) {
            if (item?.uuid === state.selectedItem.value.uuid) {
              state.selectedItem.value = item;
            }
          }
        }
        return options?.raw ? res.data : state.items.value;
      })
      .catch((err) => {
        const errMessage = 'Error loading.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const getCrawlJobs = async (uuid: string): Promise<CrawlJobInterface[] | undefined> => {
    state.isLoading.value = true;
    Vue.$log.debug('Extract Job (export): Fetching crawl jobs');
    const url = `${apiPath}/${uuid}/crawl-job?limit=100&fields[uuid]=1&fields[state]=1&fields[entityType]=1&fields[targetDomoSetUuid]=1&fields[stats]=1&fields[createdAt]=1`;
    return axios
      .get(url)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        return res.data._embedded;
      })
      .catch((err) => {
        const errMessage = 'getCrawlJobs: Error loading.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const addOrReplaceItemInItems = (newItem: ExportModel): void => {
    if (state.items.value.some((item: ExportModel) => item.uuid === newItem.uuid)) {
      state.items.value.forEach((item: ExportModel, i: number) => {
        if (item.uuid === newItem.uuid) {
          Vue.$log.debug(`Replacing item in items: ${newItem.uuid}`);
          // Note: we must splice instead of assign to trigger render update.
          state.items.value.splice(i, 1, newItem);
        }
      });
    } else {
      Vue.$log.debug(`Adding export to items: ${newItem.uuid}`);
      state.items.value.push(newItem);
    }
    if (newItem?.uuid !== undefined && state.selectedItem?.value?.uuid === newItem?.uuid) {
      Vue.$log.debug(`Replacing selected item: ${newItem.uuid}`);
      state.selectedItem.value = newItem;
    }
  };

  const getItem = async (uuid: string): Promise<ExportModel | undefined> => {
    if (!uuid) {
      const errMessage = 'Can not get export without ID';
      Vue.$log.error(errMessage);
      return undefined;
    }
    state.isLoading.value = true;
    return axios
      .get(`${apiPath}/${uuid}`)
      .then((res) => {
        Vue.$log.debug('Returned from API: ', res.data);
        if (!res?.data?.uuid) {
          Vue.$log.debug('FAIL! uuid is not in response so cannot add item');
          return res.data;
        }
        addOrReplaceItemInItems(res.data);
        return res.data;
      })
      .catch((err) => {
        const errMessage = 'Error getting export.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  /**
   * Refresh the selected item. If it is not selected, select it.
   */
  const refreshItem = async (uuid: string, forceRefresh = false): Promise<void> => {
    Vue.$log.debug(`Refreshing EXPORT item ${uuid} with force refresh ${forceRefresh}`);
    // First, attempt to select the item if it is not selected.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
    // Next, if we are forcing refresh or the item is not found (which we infer
    // from an empty selected item), go get it.
    if (uuid && (forceRefresh || state.selectedItem?.value?.uuid !== uuid)) {
      await getItem(uuid);
    }
    // Finally, if the selected item doesn't match, select it.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
  };

  const downloadJobReport = async (
    exportJobUuid: string,
    reportType: ReportType = ReportType.DETAIL
  ): Promise<any | undefined> => {
    Vue.$log.debug('downloadJobReport: Downloading job report for export job: ', exportJobUuid);
    if (!exportJobUuid) {
      const errMessage = 'downloadJobReport: Need a uuid to download the export job report.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .get(`${apiPath}/${exportJobUuid}/report/${reportType}/download`)
      .then((res) => {
        if (res?.status >= 200 || res?.status <= 299) {
          Vue.$log.debug('downloadJobReport: successfully download report');
          return res.data;
        } else {
          const errMessage =
            'downloadJobReport: Invalid response when trying to download the export job report.';
          Vue.$log.error(errMessage, res);
          throw new UserMessageError(`Could not download report (${res.status})`);
        }
      })
      .catch((err) => {
        const errMessage = 'downloadJobReport: Error caught when trying to download report.';
        Vue.$log.error(errMessage, err);
        if (err?.response?.data?.message) {
          throw new UserMessageError(`Could not download report: ${err.response.data.message}`);
        }
        throw new UserMessageError(`Could not download report (Request Error)`);
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const runDownloadJobReport = async (exportJobUuid?: string): Promise<void> => {
    if (!exportJobUuid) {
      return;
    }
    state.isLoading.value = true;
    let report: any;
    try {
      report = await downloadJobReport(exportJobUuid);
      if (!report) {
        throw new UserMessageError('The report could not be downloaded');
      }
    } catch (e) {
      if (e instanceof UserMessageError) {
        toast.error(e.message);
        return;
      }
      toast.error('Could not download report');
      return;
    }
    const downloadElement = createFileDownloadElement({
      document,
      blobData: report,
      fileName: 'export-job-report-detail.csv'
    });
    if (downloadElement?.click) {
      downloadElement.click();
      toast.success('Downloaded report');
      state.isLoading.value = false;
    } else {
      toast.error('Unable to download report');
      state.isLoading.value = false;
    }
  };

  const getJobErrorReport = async (
    exportJobUuid: string,
    crawlJobUuid: string,
  ): Promise<CrawlJobRequestReport[] | undefined> => {
    Vue.$log.debug(
      'getJobErrorReport: Get job report for export job: ',
      exportJobUuid
    );
    if (!exportJobUuid) {
      const errMessage = 'getJobErrorReport: Need a uuid to get the export job report.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .get(`${apiPath}/${exportJobUuid}/crawl-job/${crawlJobUuid}/error-report`)
      .then((res) => {
        if (res?.status >= 200 || res?.status <= 299) {
          Vue.$log.debug('getJobErrorReport: successfully get report');
          return res.data;
        } else {
          const errMessage =
            'getJobErrorReport: Invalid response when trying to get the export job report.';
          Vue.$log.error(errMessage, res);
          throw new UserMessageError(`Could not get error report (${res.status})`);
        }
      })
      .catch((err) => {
        const errMessage = 'getJobErrorReport: Error caught when trying to get report.';
        Vue.$log.error(errMessage, err);
        if (err?.response?.data?.message) {
          throw new UserMessageError(
            `Could not get error report: ${err.response.data.message}`
          );
        }
        throw new UserMessageError(`Could not get error report (Request Error)`);
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const downloadJobErrorReport = async (
    exportJobUuid: string,
    crawlJobUuid: string,
  ): Promise<any | undefined> => {
    Vue.$log.debug(
      'downloadJobErrorReport: Downloading job report for export job: ',
      exportJobUuid
    );
    if (!exportJobUuid) {
      const errMessage = 'downloadJobErrorReport: Need a uuid to download the export job report.';
      Vue.$log.error(errMessage);
      return;
    }
    state.isLoading.value = true;
    return axios
      .get(`${apiPath}/${exportJobUuid}/crawl-job/${crawlJobUuid}/error-report/download`)
      .then((res) => {
        if (res?.status >= 200 || res?.status <= 299) {
          Vue.$log.debug('downloadJobErrorReport: successfully download report');
          return res.data;
        } else {
          const errMessage =
            'downloadJobErrorReport: Invalid response when trying to download the export job report.';
          Vue.$log.error(errMessage, res);
          throw new UserMessageError(`Could not download error report (${res.status})`);
        }
      })
      .catch((err) => {
        const errMessage = 'downloadJobErrorReport: Error caught when trying to download report.';
        Vue.$log.error(errMessage, err);
        if (err?.response?.data?.message) {
          throw new UserMessageError(
            `Could not download error report: ${err.response.data.message}`
          );
        }
        throw new UserMessageError(`Could not download error report (Request Error)`);
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const runDownloadJobErrorReport = async (
    exportJobUuid?: string,
    crawlJobUuid?: string,
    entityType?: string
  ): Promise<void> => {
    if (!exportJobUuid || !crawlJobUuid || !entityType) {
      toast.success('Could not download error report');
      return;
    }
    state.isLoading.value = true;
    let report: any;
    try {
      report = await downloadJobErrorReport(exportJobUuid, crawlJobUuid);
      if (!report) {
        throw new UserMessageError('The report could not be downloaded');
      }
    } catch (e) {
      if (e instanceof UserMessageError) {
        toast.error(e.message);
        return;
      }
      toast.error('Could not download error report');
      return;
    }
    const downloadElement = createFileDownloadElement({
      document,
      blobData: report,
      fileName: `export-job-report-${exportJobUuid}-${entityType}.csv`
    });
    if (downloadElement?.click) {
      downloadElement.click();
      toast.success('Downloaded error report');
      state.isLoading.value = false;
    } else {
      toast.error('Unable to download error report');
      state.isLoading.value = false;
    }
  };

  const fieldsToOmitOnTransaction = [
    'uuid',
    'state',
    'updatedAt',
    'createdAt',
    'objectSummary',
    'progress'
  ];

  const createItem = async (item: ExportModel): Promise<ExportModel | undefined> => {
    if (item.label === undefined) {
      item.label = autoGeneratedLabelName();
    }
    if (await validItem(item)) {
      Vue.$log.debug('Creating new item: ', item);
      state.isLoading.value = true;
      const body = _.omit(item, fieldsToOmitOnTransaction);
      return axios
        .post<ExportModel>(`${apiPath}`, body, {
          headers: { 'content-type': 'application/json' }
        })
        .then((res) => {
          if (res.data) {
            Vue.$log.debug('Successfully created item. Refreshing items.');
            state.createdItem.value = res.data;
            state.createdItemId.value = res.data.uuid!; // eslint-disable-line
            return res.data;
          } else {
            const errMessage = 'Invalid response creating item';
            Vue.$log.error(errMessage, res);
            return undefined;
          }
        })
        .catch((err) => {
          const errMessage = 'Error caught creating item.';
          Vue.$log.error(errMessage, err);
          return undefined;
        })
        .finally(() => {
          state.isLoading.value = false;
        });
    }
  };

  const queue = async (uuid: string): Promise<number | undefined> => {
    if (!uuid) {
      Vue.$log.error('You cannot queue an export job without a uuid');
      return;
    }
    Vue.$log.debug('Queueing export job: ', uuid);
    state.isLoading.value = true;
    return axios
      .post<ExportModel>(`${apiPath}/${uuid}/transition/queue`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          Vue.$log.debug('Successfully queued export.');
        } else {
          const errMessage = 'Invalid response queueing item';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught queuing job.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const cancelExportJob = async (uuid: string): Promise<number | undefined> => {
    if (!uuid) {
      Vue.$log.error('You cannot cancel an export job without a uuid');
      return;
    }
    Vue.$log.debug('Cancelling export job: ', uuid);
    state.isLoading.value = true;
    return axios
      .post<ExportModel>(`${apiPath}/${uuid}/transition/cancel`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          Vue.$log.debug('Successfully cancelled job.');
        } else {
          const errMessage = 'Invalid response cancelling item';
          Vue.$log.error(errMessage, res);
        }
        return res.status;
      })
      .catch((err) => {
        const errMessage = 'Error caught cancelling job.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const updateItem = async (item: Partial<ExportModel>): Promise<ExportModel | undefined> => {
    if (!(await validUpdateItem(item))) {
      const errMessage = 'Not a valid update export job item';
      Vue.$log.error(errMessage);
      return undefined;
    }
    Vue.$log.debug('Updating item: ', item);
    state.isLoading.value = true;
    // Only set fields we allow updating
    const body = {
      label: item.label
    };
    return axios
      .patch<ExportModel>(apiPath + '/' + item.uuid, body)
      .then((res) => {
        if (res.data) {
          Vue.$log.debug('Successfully updated item. Refreshing items.');
          addOrReplaceItemInItems(res.data);
          return res.data;
        } else {
          const errMessage = 'Invalid response updating item';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error updating item';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const deleteItem = async (id: string): Promise<number | undefined> => {
    if (!id) {
      Vue.$log.error('Can not delete item without ID');
      return;
    }
    Vue.$log.debug('Deleting item: ', id);
    state.isLoading.value = true;
    return axios
      .delete(`${apiPath}/${id}`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          Vue.$log.debug('Successfully deleted item. Refreshing items.');
          getItems();
        } else {
          Vue.$log.error('Invalid response deleting item: ', res);
        }
        return res.status;
      })
      .catch((err) => {
        Vue.$log.error('Error caught deleting item.', id, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  const verifyItem = async (item: Partial<ExportModel>) => {
    // TODO: Add verification
    return true;
  };

  return {
    items: computed(() => state.items.value),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => state.selectedItem.value),
    selectedItemId: computed(() => state.selectedItemId.value),
    createdItemId: computed(() => state.createdItemId.value),
    createdItem: computed(() => state.createdItem.value),
    isLoading: computed(() => state.isLoading.value),
    downloadJobReport,
    runDownloadJobReport,
    getJobErrorReport,
    runDownloadJobErrorReport,
    selectItemBy,
    refreshItem,
    getItem,
    getItems,
    getCrawlJobs,
    createItem,
    updateItem,
    selectItem,
    deleteItem,
    verifyItem,
    emptyExportModel,
    queue,
    cancelExportJob
  };
}
