export interface PublicAuthBridgeModel {
  username: string;
  password: string;
  domoSetLoadJobUuid: string;
  publicAuthBridgeUuid: string;
  domainId?: string;
  region: string;
}

export enum AuthBridgeActionName {
  SECURE_CREDENTIAL_ACCESS = 'secure-credential-access'
}
