import axios from 'axios';
import Vue from 'vue';
import '@/lib/log';

import { computed, ref } from '@vue/composition-api';

import { PublicAuthBridgeModel } from './public-auth-bridge.interface';
import { AuthBridgeActionName } from '.';

const apiPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/public/auth-bridge`;
const isLoading: any = ref(true);

// eslint-disable-next-line
export function usePublicAuthBridge(useAuthBridgeQueryParams: any = undefined): any {
  Vue.$log.debug('Loaded with query', useAuthBridgeQueryParams);

  const validateSecureCredentialExport = async (
    params: PublicAuthBridgeModel
  ): Promise<{ data: any; headers: any }> => {
    const response = await axios.post(
      apiPath + `/${AuthBridgeActionName.SECURE_CREDENTIAL_ACCESS}`,
      params,
      {
        headers: { 'content-type': 'application/json' }
      }
    );
    if (response?.data) {
      return { data: response.data, headers: response.headers };
    } else {
      throw Error('Unable to verify your Five9 Credentials.');
    }
  };

  const informAppBridgeWasOpened = async (publicAuthBridgeUuid: string) => {
    const baseMessage = 'send bridge clicked event to main application';
    if (publicAuthBridgeUuid == null || publicAuthBridgeUuid == '') {
      Vue.$log.warn(`Unable to ${baseMessage} without valid bridge uuid`);
      return;
    }
    axios
      .post(apiPath + '/clicked/' + publicAuthBridgeUuid)
      .catch(() => Vue.$log.error(`Network request to ${baseMessage} failed`));
  };

  const validateSecureCredentialExportBlob = async (
    params: PublicAuthBridgeModel
  ): Promise<{ data: any; status: number; headers: any }> => {
    const response = await axios.post(
      apiPath + `/${AuthBridgeActionName.SECURE_CREDENTIAL_ACCESS}`,
      params,
      {
        headers: { 'content-type': 'application/json' },
        responseType: 'blob'
      }
    );
    if (response?.data) {
      return { data: response.data, headers: response.headers, status: response.status };
    } else {
      throw Error('Unable to verify your Five9 Credentials.');
    }
  };

  return {
    isLoading: computed(() => isLoading.value),
    validateSecureCredentialExport,
    validateSecureCredentialExportBlob,
    informAppBridgeWasOpened
  };
}
