import {
  EntityType,
  Five9ConfigApiOperationTypeName
} from '@/module/api/domo/five9-entities.interface';

export enum DomoStateName {
  DEFAULT = 'NEW',
  NEW = 'NEW',
  QUEUED = 'QUEUED',
  DONE = 'DONE',
  ERRORED = 'ERRORED',
  SKIPPED = 'SKIPPED'
}

export enum OperationModificationActionName {
  SKIP = 'skip',
  UNSKIP = 'unskip'
}
export interface OperationModification {
  uuid: string;
  action: OperationModificationActionName;
}
export interface DomoModifyOperationParams {
  operations: OperationModification[];
}

export interface OperationModificationResponse {
  uuid: string;
  action: OperationModificationActionName;
  result?: any;
  state?: any;
}

export const fakeOperationModificationRespone = () => {
  return {
    operations: [
      {
        uuid: 'asbdf',
        action: OperationModificationActionName.SKIP,
        result: 'success',
        state: 'SKIPPED'
      },
      {
        uuid: 'asdf123',
        action: OperationModificationActionName.SKIP,
        result: 'success',
        state: 'SKIPPED'
      }
    ]
  };
};

export interface DomoModifyOperationResponse {
  operations: OperationModificationResponse[];
}

export interface DomoModifyOperationListItem {
  id: string;
  name: string;
  locked: boolean;
  operationType: string;
  state: DomoStateName;
  children: DomoModifyOperationListItem[];
}

export interface DomoModifyOperationListResponse {
  countSelected: number;
  countTotal: number;
  selectedItems: string[];
  operations: DomoModifyOperationListItem[];
}

export interface DomoModel {
  uuid: string;
  domoSetUuid: string;
  dombName: string;
  entityId: string;
  parentEntityId: string;
  entityType: EntityType;
  createdAt: string;
  updatedAt: string;
  operationType: Five9ConfigApiOperationTypeName;
  domb: any;
  state: DomoStateName;
  result: string;
}
