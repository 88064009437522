import Vue from 'vue';
import CompositionApi, { toRefs } from '@vue/composition-api';

Vue.use(CompositionApi);

import { reactive } from '@vue/composition-api';
import { UiState, UseUi } from './ui.interface';

const newState: UiState = {
  drawer: false,
  feedback: false,
  wait: false,
  confirm: false
};
const state = reactive({ ...newState });

export function useUi(): UseUi {
  const drawerToggle = () => {
    Vue.$log.debug('Toggling drawer');
    state.drawer = !state.drawer;
  };

  const feedbackToggle = () => {
    state.feedback = !state.feedback;
  };

  const waitOn = () => {
    state.wait = true;
  };

  const waitOff = () => {
    state.wait = false;
  };

  const waitToggle = () => {
    state.wait = !state.wait;
  };

  const confirmOn = () => {
    state.confirm = true;
  };

  const confirmOff = () => {
    state.confirm = false;
  };

  const confirmToggle = () => {
    state.confirm = !state.confirm;
  };

  // watch(
  //   () => state.wait,
  //   (v, o) => {
  //     Vue.$log.debug(`wait changed from ${o} to ${v} `);

  //   }
  // );

  return {
    ...toRefs(state),
    drawerToggle,
    waitOn,
    waitOff,
    waitToggle,
    feedbackToggle,
    confirmToggle,
    confirmOn,
    confirmOff
  };
}
