import { AuthRoleName } from '@/module/auth/auth.interface';

export const userCanViewInternalReports = (roles: string[]): boolean => {
  return roles.some((role) => {
    return role === AuthRoleName.ADMIN || role === AuthRoleName.TRAINER;
  });
};

// DAMM-630: Allow anyone to run imports.
export const userCanUseImportJob = (roles: string[]): boolean => {
  return true;
};

// DAMM-776: Disable unless admin
export const userCanUseImportSyncJob = (roles: string[]): boolean => {
  return roles.some((role) => {
    return role === AuthRoleName.ADMIN;
  });
};

export const userCanUseDomainFilter = (roles: string[]): boolean => {
  return roles.some((role) => {
    return role === AuthRoleName.ADMIN;
  });
};

export const userCanSeeOwnership = (roles: string[]): boolean => {
  return roles.some((role) => {
    return role === AuthRoleName.ADMIN;
  });
};

export const userCanUseTransformJobs = (roles: string[]): boolean => {
  // TODO: decide what the right role is
  return roles.some((role) => {
    return (
      role === AuthRoleName.ADMIN || role === AuthRoleName.TESTER || role === AuthRoleName.ADVANCED
    );
  });
};

export const canSeeEntireOperation = (roles: string[]): boolean => {
  // TODO: decide what the right role is
  return roles.some((role) => {
    return (
      role === AuthRoleName.ADMIN || role === AuthRoleName.TESTER || role === AuthRoleName.ADVANCED
    );
  });
};
