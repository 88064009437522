import '@/lib/log';
import CompositionApi, { reactive, ref, toRefs, watch } from '@vue/composition-api';
import axios from 'axios';
import { } from 'date-fns';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import localForage from 'localforage';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';
import {
  AssertionContext,
  AuthMessage,
  AuthRole,
  AuthState,
  AuthVerifyTokenRequest,
  JwtResponse,
  PortalAuthResponse,
  UseAuth
} from './auth.interface';

Vue.use(CompositionApi);

const JWT_IGNORE_EXPIRATION = process.env.VUE_APP_JWT_IGNORE_EXPIRATION === 'true' ? true : false;
const whoAmIUrl = process.env.VUE_APP_API_BASE_URL + '/auth/user/me';

const debounce = 300;
const stateName = 'auth';
localForage.config({
  name: process.env.VUE_APP_VERSION_NAME,
  storeName: stateName
});

const newState: AuthState = {
  authMessage: AuthMessage.LOADING,
  authToken: '',
  authTokenExpires: 0,
  authTokenExpiresIn: 0,
  authTokenCreated: 0,
  roles: [],
  isAdmin: false,
  isUser: false,
  isAuthenticated: false,
  isAuthorized: false, // future use for permission management per role
  portalUsername: '',
  portalUserId: '',
  portalDomainName: '',
  portalDomainId: '',
  portalSlug: '',
  organizationId: ''
};

const emptyAssertionContext = (): AssertionContext => {
  return { userId: '', organizationId: '', roles: [] };
};

const assertionContext = ref(emptyAssertionContext());

const state = reactive({ ...newState });

const toast = createToastInterface({ maxToasts: 5 });

export function useAuth(): UseAuth & any {
  Vue.$log.debug('Loaded.');
  // Anything the state can be "watched" and acted on
  //
  // Conversely, any component importing and using useAuth() can have access to the
  // return from it, which will be reactive.

  const verifyToken = async (verify: AuthVerifyTokenRequest) => {
    const url = `${process.env.VUE_APP_PORTAL_API_BASE_URL}${process.env.VUE_APP_PORTAL_API_VERIFY}/${verify.portalToken}/${verify.appId}`;
    state.authMessage = AuthMessage.VALIDATE;
    const res = await axios.get<PortalAuthResponse>(url);
    return res.data;
  };

  const authorizeApp = async (authenticate: PortalAuthResponse) => {
    Vue.$log.debug('Authorizing app...');
    const url = `${process.env.VUE_APP_API_AUTHORIZE}`;
    state.authMessage = AuthMessage.AUTHORIZE;
    const res = await axios.post<JwtResponse>(url, authenticate);
    Vue.$log.debug(`Response from ${url}`, res.data);
    return res.data;
  };

  const addBearerToken = async (jwt: JwtResponse) => {
    Vue.$log.debug('Adding JWT token to axios: ', jwt.token);
    state.authMessage = AuthMessage.SECURE;
    state.authToken = jwt.token;
    state.authTokenExpires = jwt.expires;
    state.authTokenExpiresIn = jwt.expires_in;
    state.authTokenCreated = jwt.created;
    axios.defaults.headers['Authorization'] = `Bearer ${jwt.token}`;
  };

  const resetState = (): void => {
    if (process.env.VUE_APP_DEBUG_DISABLE_AUTH_STATE_RESET) {
      Vue.$log.debug('Skipping state reset per configuration.');
    } else {
      Vue.$log.debug('Resetting state: ', newState);
      Object.assign(state, { ...newState });
    }
  };

  const getAssertionContext = async (): Promise<AssertionContext> => {
    const response = await axios.get(whoAmIUrl);
    if (response?.data == null) {
      throw new Error('Unable to validate identity.');
    }
    return response.data;
  };

  const refreshAssertionContext = async (forceRefresh = false): Promise<void> => {
    if (!forceRefresh && assertionContext.value.userId !== '') {
      Vue.$log.debug(
        'Assertion context is not refreshed because it was not forced and a value exists.'
      );
      return;
    }
    try {
      const newContext = await getAssertionContext();
      assertionContext.value = newContext;
      Vue.$log.info('Assertion context refreshed', assertionContext.value);
    } catch (e) {
      Vue.$log.error('Error refreshing assertion context, setting to empty', e);
      assertionContext.value = emptyAssertionContext();
    }
  };

  const getCurrentOrgQuery = async () => {
    const currentOrgQuery =
      assertionContext.value?.organizationId && assertionContext.value?.organizationId !== ''
        ? { 'filter[organizationId]': assertionContext.value.organizationId }
        : undefined;
    return currentOrgQuery;
  };

  const authorizeState = (JwtResponse: JwtResponse, portalAuthResponse: PortalAuthResponse) => {
    Vue.$log.info('PORTALAUTHRESPONSE', portalAuthResponse);
    addBearerToken(JwtResponse);
    const appConfig = portalAuthResponse.appConfig || {};
    const roles = appConfig?.roles || [];
    state.isAuthenticated = true;
    state.authMessage = AuthMessage.AUTHORIZE;
    state.isUser = true;
    state.roles = appConfig?.roles || [];
    state.isAdmin = roles.includes(AuthRole.Admin);
    state.portalUsername = portalAuthResponse.username;
    state.portalDomainName = portalAuthResponse.domain;
    state.portalDomainId = portalAuthResponse.domainId;
    state.portalSlug = portalAuthResponse.slug;
    state.portalUserId = portalAuthResponse.customerId;
    state.organizationId = JwtResponse.appOrganizationId;
  };

  const saveState = async () => {
    const toSave = {};
    // TODO: De-wonkify the typing here
    Object.keys(newState).forEach((k) => {
      // @ts-ignore
      toSave[k] = state[k];
    });

    try {
      Vue.$log.debug('Saving: ', toSave, newState);
      await localForage.setItem('state', toSave);
    } catch (err) {
      Vue.$log.error('Unable to save state.', err);
    }
  };

  const deleteState = async () => {
    if (process.env.VUE_APP_DEBUG_DISABLE_AUTH_STATE_DELETE) {
      Vue.$log.debug('Skipping deleteState per configuration.');
    } else {
      try {
        Vue.$log.debug('Deleting state from local storage');
        await localForage.removeItem('state');
      } catch (err) {
        Vue.$log.error('Unable to delete state', err);
      }
    }
  };

  const getState = async () => {
    //
    let restoredState: AuthState;
    try {
      const restored = await localForage.getItem<AuthState>('state');
      if (restored) {
        restoredState = restored;
      } else {
        throw Error();
      }

      if (restoredState.authToken) {
        const now = new Date().getTime() / 1000;
        const token = jwtDecode<JwtPayload>(restoredState.authToken);
        if (token) {
          if (!JWT_IGNORE_EXPIRATION && token.exp && token.exp < now) {
            Vue.$log.error('Stored state is expired. Deleting.');
            await deleteState();
          } else {
            Object.assign(state, restoredState);
            await addBearerToken({
              token: state.authToken,
              created: state.authTokenCreated,
              expires: state.authTokenExpires,
              // eslint-disable-next-line
              expires_in: state.authTokenExpiresIn,
            });
            Vue.$log.debug('Successfully restored state.', restoredState);
          }
        } else {
          const errMessage = `Local state mismatch. Purgring.`;
          throw Error(errMessage);
        }
      } else {
        const errMessage = `Token non-existent in local state.`;
        throw Error(errMessage);
      }
    } catch (err) {
      Vue.$log.error('Can not restore state from localstorage.', err);
      deleteState();
    }
  };
  //http://localhost:8080/launcher/df86994c-737a-11eb-935e-005056ad12ae/5f6b38898e904f2e2bdc7a63

  const portalLogin = (
    message = `Your session is invalid or has expired. Redirecting...`
  ): void => {
    Vue.$log.debug('Redirecting to portal...');
    const timeout = 5000;
    const url = process.env.VUE_APP_PORTAL_URL || 'https://login.five9.com';

    toast.updateDefaults({
      filterBeforeCreate: (toast, toasts) => {
        if (toasts.filter((t) => t.type === toast.type).length !== 0) {
          // Returning false discards the toast
          return false;
        }
        // You can modify the toast if you want
        return toast;
      }
    });
    toast.error(`${message}`, {
      onClose: () => {
        if (process.env.VUE_APP_DEBUG_DISABLE_AUTH_REDIRECT) {
          Vue.$log.debug(`Skipping redirect per configuration.`);
        } else {
          Vue.$log.debug(`Redirecting to ${url}`);
          window.location.href = url;
        }
      },
      timeout,
      closeOnClick: true
    });
  };

  const appLogout = (): void => {
    portalLogin('Logging out of application...');
    state.isAdmin = false;
    state.isUser = false;
    // if (!state.isAuthenticated) {
    // } else {
    state.authToken = '';
    state.isAuthenticated = false;
    // }
  };

  const authenticateApp = async (verify: AuthVerifyTokenRequest): Promise<void> => {
    try {
      // Five9 Token passed from Portal
      // AppId from Portal
      const portalAuthResponse = await verifyToken(verify);
      if (!portalAuthResponse) {
        const errMessage = `Unable to verify token. Response is invalid.`;
        throw new Error(errMessage);
      }
      Vue.$log.debug('Response from verifyToken: ', portalAuthResponse);

      // Take that response and authorize this UI against my API
      const JwtResponse = await authorizeApp(portalAuthResponse);
      if (!JwtResponse) {
        const errMessage = `Enable to authorize app`;
        throw new Error(errMessage);
      }
      Vue.$log.debug('Response from authorizeApp: ', JwtResponse);

      authorizeState(JwtResponse, portalAuthResponse);
      state.authMessage = AuthMessage.COMPLETE;

      Vue.$log.debug('Attempting to save the state locally');
      await saveState();
    } catch (err) {
      // If authentication failed, but we allow ignoring the expiration, try
      // getting it from state.
      if (JWT_IGNORE_EXPIRATION) {
        await getState();
        if (state.isAuthenticated) {
          return;
        }
      }

      Vue.$log.error('Error caugh in authenticateApp: ', err);
    } finally {
      //
    }
  };

  // https://v3.vuejs.org/guide/reactivity-computed-watchers.html#watching-reactive-objects
  watch(
    () => state.authToken,
    (v, o) => {
      setTimeout(async () => {
        Vue.$log.debug(`authToken changed from ${o} to ${v} `);
        if (v === o) {
          return;
        }
        if (!v || v === '' || v === undefined) {
          await deleteState();
          portalLogin();
        }
      }, debounce);
    }
  );

  watch(
    () => state.isAuthenticated,
    (v, o) => {
      setTimeout(async () => {
        Vue.$log.debug(`isAuthenticated changed from ${o} to ${v} `);
        if (v !== true) {
          await deleteState();
          portalLogin();
        }
      }, debounce);
    }
  );

  // state property won't be wrapped in a type
  // TODO: Figure out proper typing to return the state object wrapped in refs
  return {
    ...toRefs(state),
    state: toRefs(state),
    assertionContext,
    refreshAssertionContext,
    authenticateApp,
    resetState,
    portalLogin,
    getState,
    appLogout,
    getCurrentOrgQuery
  };
}
