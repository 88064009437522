import { JobProgressDto, OperationSummary } from '@/module/api/common/common.interface';
import { toNum } from '@/utils';

export enum LoadJobType {
  IMPORT = 'IMPORT',
  SYNC = 'SYNC',
  RESET = 'RESET',
  RESTORE = 'RESTORE'
}

export enum SourceType {
  DOMAIN = 'DOMAIN'
}

export interface SourceRef {
  type: SourceType;
  uuid: string;
  extractJobUuid?: string;
}

export enum LoadJobActionButtonType {
  CONFIGURE_JOB = 'configureJob',
  RUN_JOB = 'runJob'
}

export enum UnitLabelName {
  CRAWL_JOBS = 'Crawl Jobs',
  LOADING_GROUPS = 'Loading Groups'
}

export enum ValidationStateName {
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED'
}

export enum LoadJobStateName {
  NEW = 'NEW',
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE'
}

export enum LoadJobStateNameFriendly {
  NEW = 'New',
  READY = 'Ready',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
  CANCELLED = 'Cancelled',
  ERRORED = 'Errored'
}

export enum LoadJobTypeNameFriendly {
  IMPORT = 'Import',
  SYNC = 'Sync',
  RESET = 'Reset',
  RESTORE = 'Restore'
}

export enum ValidationSummaryCountType {
  ERROR = 'error',
  WARNING = 'warning',
  NOTICE = 'notice'
}

export const getLoadJobSetupComponentNameByJobType = (jobType: LoadJobType) => {
  switch (jobType) {
    case LoadJobType.IMPORT:
      return 'ImportJobImportSetupWizard';
    case LoadJobType.SYNC:
      return 'ImportJobImportSetupWizard';
    case LoadJobType.RESET:
      return 'ImportJobResetSetupWizard';
    case LoadJobType.RESTORE:
      return 'ImportJobImportSetupWizard';
    default:
      return undefined;
  }
};

export const loadJobStateNamesFriendly = (): Record<LoadJobStateName, LoadJobStateNameFriendly> => {
  return {
    NEW: LoadJobStateNameFriendly.NEW,
    READY: LoadJobStateNameFriendly.READY,
    IN_PROGRESS: LoadJobStateNameFriendly.IN_PROGRESS,
    DONE: LoadJobStateNameFriendly.DONE,
    CANCELLED: LoadJobStateNameFriendly.CANCELLED
  };
};

export const loadJobTypeNamesFriendly = (): Record<LoadJobType, LoadJobTypeNameFriendly> => {
  return {
    IMPORT: LoadJobTypeNameFriendly.IMPORT,
    SYNC: LoadJobTypeNameFriendly.SYNC,
    RESET: LoadJobTypeNameFriendly.RESET,
    RESTORE: LoadJobTypeNameFriendly.RESTORE
  };
};

export const getLoadJobStateNameFriendly = (
  stateName: LoadJobStateName
): LoadJobStateNameFriendly => {
  const friendlyNames = loadJobStateNamesFriendly();
  if (friendlyNames[stateName] === undefined) {
    return LoadJobStateNameFriendly.NEW;
  }
  return friendlyNames[stateName];
};

export const getFriendlyLoadJobTypeName = (loadJobType: LoadJobType) => {
  return loadJobTypeNamesFriendly()[loadJobType];
};

export interface WizardSetup {
  isComplete: boolean;
  step: number;
  data: any;
}

export interface Wizards {
  setup: WizardSetup;
  [key: string]: any;
}

export interface LoadJobModel {
  uuid?: string;
  state?: LoadJobStateName;
  label: string;
  type?: LoadJobType;
  notificationEmail?: string;
  source?: SourceRef;
  domoSetUuid?: string;
  domainUuid?: string;
  updatedAt?: string;
  createdAt?: string;
  startedAt?: string;
  completedAt?: string;
  operationSummary?: OperationSummary;
  progress?: JobProgressDto;
  wizards?: Wizards;
  mostRecentValidationJob?: {
    uuid: string;
    createdAt: string;
    updatedAt: string;
    state: ValidationStateName;
    validationSummary: {
      error: number;
      warning: number;
      notice: number;
    };
  };
}

export interface LoadJobWizardSetupModel {
  isComplete?: boolean;
  step?: number;
  isSynchronized?: boolean;
  source: {
    type: SourceType;
    uuid: string;
    extractJobUuid: string;
  };
}

// Getters - Point is that we need to access some nested value that may not exist - template doesn't allow for optional accessor, so to prevent component from crashing I'm doing this.
// IN VUE 3 - OPTIONAL ACCESSORS ARE ALLOWED
export const getLastValidationJobSummaryCount = (
  loadJob: LoadJobModel,
  countType: ValidationSummaryCountType
) => {
  if (countType === ValidationSummaryCountType.ERROR) {
    return toNum(
      loadJob?.mostRecentValidationJob?.validationSummary?.[ValidationSummaryCountType.ERROR]
    );
  }
  if (countType === ValidationSummaryCountType.WARNING) {
    return toNum(
      loadJob?.mostRecentValidationJob?.validationSummary?.[ValidationSummaryCountType.WARNING]
    );
  }
  if (countType === ValidationSummaryCountType.NOTICE) {
    return toNum(
      loadJob?.mostRecentValidationJob?.validationSummary?.[ValidationSummaryCountType.NOTICE]
    );
  }
  return 0;
};

export const isNewJobAndWizardIsNotComplete = (loadJob: LoadJobModel): boolean => {
  return !loadJob.wizards?.setup?.isComplete && loadJob.state === LoadJobStateName.NEW;
};

export const getFriendlyLoadJobType = (loadJobType: LoadJobType) => {
  return loadJobTypeNamesFriendly()[loadJobType];
};

export const getTotalOperations = (loadJob: LoadJobModel): number => {
  return toNum(loadJob.operationSummary?.total);
};

export const getTotalOperationsSucceeded = (loadJob: LoadJobModel): number => {
  const done = toNum(loadJob.operationSummary?.totalsByState?.DONE);
  const skipped = toNum(loadJob.operationSummary?.totalsByState?.SKIPPED);
  return done + skipped;
};

export const getTotalOperationsFailed = (loadJob: LoadJobModel): number => {
  return toNum(loadJob.operationSummary?.totalsByState?.ERRORED);
};

export const getTotalSuccessPercent = (loadJob: LoadJobModel) => {
  const succeeded = getTotalOperationsSucceeded(loadJob);
  const total = getTotalOperations(loadJob);
  if (total < 1) {
    return 0;
  }
  return Math.floor((succeeded / total) * 100);
};

export const getJobProgressPercentCompletion = (loadJob: LoadJobModel) => {
  const completed = toNum(loadJob.progress?.totalUnitsCompleted);
  const total = toNum(loadJob.progress?.totalUnits);
  if (!completed || !total) {
    return 0;
  }
  return Math.floor((completed / total) * 100);
};

export const getJobProgressLabel = (loadJob: LoadJobModel) => {
  return loadJob.progress?.unitLabel || 'Loading';
};
