import { JobProgressDto } from '@/module/api/common/common.interface';
import { DomainModel } from '@/module/api/domain';
import { EntityType } from '@/module/api/domo/five9-entities.interface';
import { toNum } from '@/utils';

export enum ExportTransitionName {
  PREPARE = 'PREPARE',
  RUN = 'RUN',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL'
}

export enum ExportStateName {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  ERRORED = 'ERRORED'
}

export enum ExportStateNameFriendly {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
  CANCELLED = 'Cancelled',
  ERRORED = 'Errored'
}

export const exportStateNamesFriendly = (): Record<ExportStateName, ExportStateNameFriendly> => {
  return {
    NEW: ExportStateNameFriendly.NEW,
    IN_PROGRESS: ExportStateNameFriendly.IN_PROGRESS,
    DONE: ExportStateNameFriendly.DONE,
    CANCELLED: ExportStateNameFriendly.CANCELLED,
    ERRORED: ExportStateNameFriendly.ERRORED
  };
};

export interface ExportModel {
  uuid?: string;
  state?: ExportStateName;
  label: string;
  domoSetUuid?: string;
  domainUuid: string;
  isRestorePoint: boolean;
  updatedAt?: string;
  createdAt?: string;
  objectSummary?: {
    total: number;
    totalByType: Partial<Record<EntityType, number>>;
  };
  progress?: JobProgressDto;
}

export interface CrawlJobInterface {
  uuid: string;
  entityType: string;
  targetDomoSetUuid: string;
  state: string;
  stats: {
    total: number;
    totalsByState: {
      NEW: number;
      QUEUED: number;
      SAVED: number;
      DONE: number;
      ERRORED: number;
    };
  };
  createdAt: string;
}

export interface RunExportJobParams {
  domain: DomainModel;
  label?: string;
}

export const getJobProgressPercentCompletion = (exportJob: ExportModel) => {
  const completed = toNum(exportJob.progress?.totalUnitsCompleted);
  const total = toNum(exportJob.progress?.totalUnits);
  if (!completed || !total) {
    return 0;
  }
  return Math.floor((completed / total) * 100);
};

export interface ObjectSummaryItem {
  extractJobUuid: string;
  entityType: string;
  crawlJobUuid: string;
  count: number;
  errors: number;
}

export class CrawlJobRequestReport {
  method: string;
  url: string;
  statusCode: number;
  message: string;
  detail: string;
}
