import * as dateFns from 'date-fns';

const getDomoExpirationTtlDays = (): number => {
  const defaultDays = 60;
  if (process.env.VUE_APP_DOMO_EXPIRATION_TTL_DAYS === undefined) {
    return defaultDays;
  }
  const expirationTtlDays = Number(process.env.VUE_APP_DOMO_EXPIRATION_TTL_DAYS);
  if (isNaN(expirationTtlDays)) {
    return defaultDays;
  }
  return expirationTtlDays;
};

const getDomoExpirationTimestampMs = (ttlDays: number): number => {
  return new Date().getTime() - ttlDays * 24 * 60 * 60 * 1000;
};

/**
 * How many days can a Domo last?
 */
export const DOMO_EXPIRATION_TTL_DAYS = getDomoExpirationTtlDays();

/**
 * A Domo that is older than this timestamp (ms) is expired. e.g. if today is June 9
 * and the TTL Days is 60, then the value here will be a ms on Feb 9.
 */
export const DOMO_EXPIRATION_TIMESTAMP_MS = getDomoExpirationTimestampMs(DOMO_EXPIRATION_TTL_DAYS);

/**
 * Is this date expired? i.e. older than DOMO_EXPIRATION_TTL_DAYS
 *
 * If it errors or we can't compute, say its not expired.
 */
export const isDateExpired = (date: Date): boolean => {
  try {
    const timestampMs = date.getTime();
    if (isNaN(timestampMs)) {
      return false;
    }
    if (timestampMs < DOMO_EXPIRATION_TIMESTAMP_MS) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

/**
 * @see isDateExpired()
 */
export const isDateStringExpired = (dateString: string): boolean => {
  return isDateExpired(new Date(dateString));
};


export const getJobDuration = (theJob: any): string => {
  if (!theJob || !theJob.startedAt) {
    return '';
  }
  let endDate;
  if (theJob.completedAt) {
    endDate = new Date(theJob.completedAt);
  } else if (theJob.state === 'IN_PROGRESS') {
    endDate = new Date();
  }
  if (!endDate) {
    return '';
  }
  const interval = dateFns.intervalToDuration({
    start: new Date(theJob.startedAt),
    end: endDate,
  });
  const duration = dateFns.formatDuration(interval, {
    delimiter: ', ',
  });
  return duration;
};

/**
 * Ideally we want to check the expiry against when the job was started, but if we don't have that, use the createdAt, else use Date.now().
 */
export const getJobDateToCheckExpiry = (theJob: any): Date => {
  if (!theJob) {
    return new Date();
  }
  let startDate: Date;
  if (theJob.startedAt) {
    startDate = new Date(theJob.startedAt);
  } else if (theJob.state === 'IN_PROGRESS') {
    startDate = new Date();
  } else {
    startDate = new Date(theJob.createdAt);
  }
  if (!startDate) {
    return new Date();
  }
  return startDate;
};

/**
 * Assumption: this is only called if the job is not yet expired.
 */
export const getJobExpiresInDuration = (theJob: any): string | undefined => {
  const startDate: Date = getJobDateToCheckExpiry(theJob);
  const durationStart = DOMO_EXPIRATION_TIMESTAMP_MS;
  const durationEnd = startDate.getTime();
  // We must have called this on an expired one...
  if (!durationEnd || durationStart > durationEnd) {
    return undefined;
  }
  const interval = dateFns.intervalToDuration({
    start: durationStart,
    end: durationEnd,
  });
  const duration = dateFns.formatDuration(interval, {
    format: ['years', 'months', 'weeks', 'days'],
    delimiter: ', ',
  });
  return duration;
};

/**
 * Check if a model is expired. This can be used on more than just the Domo model. If it can't compute the
 * createdAt then it will say its not expired. It will ONLY say its expired if it can find the createdAt time
 * and if that time is expired.
 */
export const isModelExpired = (model: any): boolean => {
  if (!('createdAt' in model) || !model.createdAt) {
    return false;
  }
  return isDateStringExpired(model.createdAt);
};
